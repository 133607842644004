<template>
  <div>
    <template>
      <div class="flix-container flix-form-group">
        <div class="flix-row">
          <div class="flix-col-md-4 flix-col-md-flix-offset-4">
            <div class="flix-well flix-text-center">
              <div class="flix-form-group">
                <div class="flix-html-h1" >
                  <flixIcon :id="'group'"/>
                </div>
                <div class="flix-html-h2">
                  {{ $t("message.edit", {name: $tc("message.ressources", 2)}) }}
                </div>
              </div>
              <div v-if="sendet" class="flix-alert flix-alert-success">{{ $t('message.successfullSendet') }}</div>
              <form v-else @submit.prevent="sendLogin()">
                <div class="flix-form-group">
                  <input type="email" v-model="email" class="flix-form-control" spellcheck="false" :placeholder="$t('message.email')"/>
                </div>
                <div class="flix-form-group flix-text-success">
                  <flixIcon :id="'envelope'" /> {{ $t('message.getValidationEmail') }}
                </div>
                <transition name="flixFadeIn">
                  <div v-if="checkmail">
                    <div class="flix-form-group">
                      <saveBtn :settings="{block: true}" :onClick="function(){ sendLogin() }">
                        <template v-slot:text>
                          {{ $t("message.send") }}
                        </template>
                      </saveBtn>
                    </div>
                  </div>
                </transition>
              </form>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  components: {},
  props: { callback: Function },
  data () {
    return {
      email: '',
      checkmail: false,
      sendet: false
    }
  },
  watch: {
    email (val) { this.checkmail = false; if (this.email && this.$checkEmail(this.email)) { this.checkmail = true } }
  },
  methods: {
    sendLogin () {
      var link = this.$flix_url + '/shares/s/' + btoa(btoa(btoa(this.email)))
      var send = {}
      send.noLogin = true
      send.to = this.email
      send.title = this.$t('email.ressourcesLogin').title
      send.text =
      this.$t('email.ressourcesLogin').text
        .split('{link}').join('<a href="' + link + '">' + link + '</a>')

      this.$flix_post({ url: 'ressources/sharingmail', data: send, callback: function (ret) { if (ret.data[0]) { this.sendet = true } }.bind(this) })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
